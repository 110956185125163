import React from "react"
import { Link } from "gatsby"
import { Col, Row, Card } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons"

import PageLayout from "../../components/PageLayout"
import Hero from "../../components/Hero"
import SEO from "../../components/seo"

import LaurenImage from "./images/lauren.jpeg"
import TomImage from "./images/tom.jpeg"

const AboutPage = () => (
  <PageLayout
    title={"About Freshnode"}
    hero={
      <Hero
        title="About Freshnode"
        subtitle="Freshnode was started in 2013 with an aim to bring
  human-centered technology and modern delivery practices to companies of
  all sizes. We have worked with household names including ASOS, MoneySupermarket, Westfield, Jack Daniels and more."
      />
    }
  >
    <div className="mt-4">
      <SEO
        title="About Freshnode"
        description="Freshnode was started in 2013 with an aim to bring
        human-centered technology and modern delivery practices to companies of
        all sizes. We have worked with household names including ASOS, MoneySupermarket, Westfield, Jack Daniels and more."
      />
      <Row>
        <Col xs={12} lg={6}>
          <Card
            className="shadow-sm mb-4 mb-md-0"
            style={{ borderTop: "5px solid var(--primary)" }}
          >
            <div className="card-body d-flex align-items-start mb-4">
              <img
                className="d-none d-md-block mr-4 rounded shadow-sm"
                src={TomImage}
                width={80}
                height={80}
                alt="Tom Hallam"
              />
              <div>
                <h3>Tom Hallam</h3>
                <p className="text-muted">Director - Technology</p>
                <p className="">
                  Tom is an AWS-certified cloud architect and full stack
                  engineer. He works with teams to design, build and run
                  software systems in a way that achieves business goals and
                  promotes well being within teams.
                </p>
                <p className="mb-0 pb-0 text-muted">
                  <a href="https://carbontrail.co">
                    CarbonTrail - Automated Carbon Footprint Assessment
                  </a>
                </p>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={12} lg={6}>
          <Card
            className="shadow-sm"
            style={{ borderTop: "5px solid var(--primary)" }}
          >
            <div className="card-body d-flex align-items-start mb-4">
              <img
                className="d-none d-md-block mr-4 rounded shadow-sm"
                src={LaurenImage}
                width={80}
                height={80}
                alt="Lauren Dowding"
              />
              <div>
                <h3>Lauren Dowding</h3>
                <p className="text-muted">
                  Director - Strategy & Service Design
                </p>
                <p>
                  Lauren is passionate about digital in all its forms. She was
                  number 9 in The Drum 30 under 30 competition celebrating women
                  in Digital. Lauren helps organisations cut through the
                  nonsense and make the decisions that matter for their bottom
                  line.
                </p>
                <p className="mb-0 pb-0 text-muted">
                  <a href="https://linkedin.com/in/laurendowding">
                    {" "}
                    <FontAwesomeIcon
                      className="mr-2"
                      size="1x"
                      icon={faLinkedin}
                    />
                    LinkedIn
                  </a>
                </p>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  </PageLayout>
)

export default AboutPage
